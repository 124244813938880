<template>
  <div v-if="showModal" class="croppers">
    <div class="cropper-content">
      <div class="cropper" style="text-align: center">
        <vue-Cropper
          ref="croppercommon"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="true"
          :full="option.full"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :fixed="option.fixed"
          :centerBox="option.centerBox"
          :infoTrue="option.infoTrue"
          :fixedBox="option.fixedBox"
          :mode="option.mode"
          :autoCropWidth="200"
          :autoCropHeight="200"
        ></vue-Cropper>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <a-button @click="dialogVisibleHide">{{
        getString(strings.Global_Cancel)
      }}</a-button>
      <a-button type="primary" @click="finish">{{
        getString(strings.Global_Ok)
      }}</a-button>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import { postMindmapUploadImage } from "../../../common/netWork/mind_map_api";
import imageToStyle from "../../../utils/ImageToolkit";
export default {
  props: {
    showCropper: {
      type: Boolean,
      defualt() {
        return true;
      },
    },
    imageUrl: {
      type: String,
      defualt() {
        return '';
      },
    },
    imgHeight: {
      type: Number,
      defualt() {
        return 0;
      },
    },
    imgWidth: {
      type: Number,
      defualt() {
        return 0;
      },
    }
  },
  data() {
    return {
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 1920, // 默认生成截图框宽度
        autoCropHeight: 1920, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: "cover",
      },
      val:0,
      picsList: [], //页面显示的数组
      // 防止重复提交
      loading: false,
      imgUrlData: "",
      strings: strings,


      showModal: false, //是否展示截图弹框。
    };
  },
  mounted() {
    // if (this.option.img == "") {
    //   let that = this;
    //   if (this.$store.state.cropperImgUrl == null) {
    //     return;
    //   }

    //   //获取图片的宽高
    //   let img = new Image();img.setAttribute('crossOrigin','Anonymouse');
    //   img.src = this.$store.state.cropperImgUrl.img;
    //   img.onload = function () {
    //     that.option.autoCropWidth = img.width;
    //     that.option.autoCropHeight = img.height;
    //   };
    //   this.option.img = this.$store.state.cropperImgUrl.img;
    //   //设置截图框的比例
    //   if (this.$store.state.cropperImgUrl.type == "storm") {
    //     this.option.fixedNumber = [5, 4];
    //     this.option.fixedBox = true;
    //   } else if (this.$store.state.cropperImgUrl.type == "uploadImg") {
    //     // this.option.fixedNumber = [1, 1];
    //     this.option.fixedBox = false;
    //     this.option.fixed = false;
    //   }
    // }
  },
  methods: {
    ...mapMutations(["showCropperImg", "showCropperImgUrl", "setDomImg"]),

    minWidth() {
      if(this.imgWidth > this.imgHeight) {
        return this.imgHeight
      } else {
        return this.imgWidth
      }

    },

    hiddenCropper(obj) { //隐藏截图框
      this.$emit('hiddenCropper',obj);
    },
    getString(i) {
      return getString(i);
    },
    dialogVisibleHide() {
      // this.showCropperImg(false);
      let obj = {
        show: false,
        confirm: false,
      }
      this.hiddenCropper(obj);

    },
    finish() {
      /**
       * this.$store.state.cropperImgUrl.type
       * storm 风暴截图
       * uploadImg 导图内部上传的图片例如 素材库 本地上传
       */
      this.$refs.croppercommon.getCropData((data) => {
        //图片转化成blob类型
        function blobData() {
          var arr = data.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        }
        var blob = blobData();

      let obj = {
        show: false,
        confirm: true,
        imgData: blob
      }
      this.hiddenCropper(obj);
      });
    },
  },
  watch: {
    showCropper: {
      handler(newVal) {
        this.showModal = newVal;
      },
      immediate: true
    },
    imageUrl: {
      handler(newVal) {
        // this.showModal = newVal;
        if (newVal != null && newVal != '') {
                // let that = this;
                // let img = new Image();img.setAttribute('crossOrigin','Anonymouse');
                // img.src = newVal;
                // img.onload = function () {
                //   // that.option.autoCropWidth = img.width;
                //   // that.option.autoCropHeight = img.height;
                //   // that.$tools.console('img.width', img.width);
                //   // that.$tools.console('that.option.autoCropWidth', that.option.autoCropWidth);
                // };
                this.option.img = newVal;
                this.option.fixedNumber = [1, 1];
                this.option.fixedBox = true;

                // if (this.$store.state.cropperImgUrl.type == "storm") {
                //   this.option.fixedNumber = [5, 4];
                //   this.option.fixedBox = true;
                // } else if (this.$store.state.cropperImgUrl.type == "uploadImg") {
                //   // this.option.fixedNumber = [1, 1];
                //   this.option.fixedBox = false;
                //   this.option.fixed = false;
                // }
        }
      },
      immediate: true
    },

    imgHeight: {
      handler(newVal) {
        // this.showModal = newVal;
        if (newVal != null && newVal != '') {
          // let val  = this.minWidth();
          // this.option.autoCropWidth = val;
          // this.option.autoCropHeight = val;
        }
      },
      immediate: true
    },

    imgWidth: {
      handler(newVal) {
        if (newVal != null && newVal != '') {
          // let val  = this.minWidth();
          // this.option.autoCropWidth = val;
          // this.option.autoCropHeight = val;
        }
      },
      immediate: true
    }

    // "$store.state.cropperImgUrl.img"(
    //   newCropperImgUrlAccout,
    //   olCropperImgUrlAccout
    // ) {
    //   let that = this;
    //   let img = new Image();img.setAttribute('crossOrigin','Anonymouse');
    //   img.src = newCropperImgUrlAccout;
    //   img.onload = function () {
    //     that.option.autoCropWidth = img.width;
    //     that.option.autoCropHeight = img.height;
    //   };
    //   this.option.img = newCropperImgUrlAccout;

    //   if (this.$store.state.cropperImgUrl.type == "storm") {
    //     this.option.fixedNumber = [5, 4];
    //     this.option.fixedBox = true;
    //   } else if (this.$store.state.cropperImgUrl.type == "uploadImg") {
    //     // this.option.fixedNumber = [1, 1];
    //     this.option.fixedBox = false;
    //     this.option.fixed = false;
    //   }
    // },


  },
};
</script>
<style lang="less" scoped>
.croppers {
  z-index: 9999;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 100vh;
  .cropper-content {
    .cropper {
      width: auto;
      height: 100vh;
    }
  }
  .dialog-footer {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    button {
      margin: 10px 10px;
    }
  }
}
</style>