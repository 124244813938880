import LoadLocalXJMind from "../viewmodel/core/tools/mapfile/LoadLocalXJMind";

//转换图片格式类
class imageToStyle {
    base64ToBlob(data) {
        var arr = data.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
        var dataURL = canvas.toDataURL("image/" + ext);
        return dataURL;
    }

    localhostToBase64(data) {
        var that = this
        var image = new Image()
        return new Promise((resolve,reject) => {
            image.onerror=function(e){
                reject(e)
            }
            image.src = data
            image.onload = () => {
                resolve(that.getBase64Image(image))
            }
        })
    }

    webToBase64(src) {
        var that = this
        var image = new Image();image.setAttribute('crossOrigin','Anonymouse');
        image.src = LoadLocalXJMind.getLocalFilePath(src);
        return new Promise((resolve) => {
            image.onload = () => {
                resolve(that.getBase64Image(image))
            }
        })
    }
}

export default imageToStyle